import jwtMiddlewares from './jwt-middleware';
import redirectMiddlewares from './redirect-middleware';
import loggerMiddleware from './loggerMiddleware';
import alertMiddleware from './alertMiddleware';

export default [
    // jwtMiddlewares,
    redirectMiddlewares,
    // loggerMiddleware,
    // alertMiddleware
]